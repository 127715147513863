import React from "react";
import Header from "../components/header";

export default function About() {
  return (
    <div>
      <Header />
      Hell!o about!
    </div>
  );
}
